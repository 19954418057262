.yahooFeed {
  :global .yahooRS {
    .iconContainer {
      min-width: 32px;
    }

    .relatedTopicsHeader {
      padding-left: 0.5em;
      font-size: small;
      color: white;
    }

    ul {
      text-align: center;
      margin: 0;
      padding: 0.5em;
      padding-top: 0;
    }

    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      line-height: normal;
      margin-top: 0;
      padding: 1.25em;
      background-color: #b8e4fc;
      border: 3px solid white;
      border-radius: 5px;
      font-weight: bold;
      color: black;

      a {
        padding: 1.3em 1.5em;
        text-decoration: none;
        font-size: 23px;
        color: black;
      }
    }
  }

  :global .yahooAds {
    ul {
      padding: 0;
      margin: 0;
    }

    .yahooFeedAdItem {
      padding: 1.5em 1em;

      b {
        // we are overriding the font-weight of a b tag
        // because this html is supplied directly from yahoo
        // but we want the style to be more similar to google ads
        font-weight: 100;
      }

      .adSiteHost {
        a {
          margin-bottom: 1em;
          color: white;
        }

        span {
          margin-right: 0.5em;
          padding: 2px;
          border: 1px solid white;
          border-radius: 2px;
          color: white;
        }
      }

      .adLink {
        display: block;
        padding: 8px 0px;
        font-size: x-large;
        font-weight: normal;
        color: white;

        b {
          text-decoration: underline;
          font-style: italic;
        }
      }
      .adDescription {
        margin: 0;
        padding-top: 1em;
        padding-left: 4em;
        color: white;
      }

      .adVisitButton {
        display: flex;
        justify-content: center;
        margin-top: 1em;

        button {
          display: flex;
          justify-content: center;
          align-items: center;
          min-width: 12em;
          min-height: 5em;
          padding: 30px 40px;
          border: 3px solid white;
          border-radius: 5px;
          background-color: #b8e4fc;
          color: black;

          a {
            font-weight: bold;
            color: black;
          }
        }
        button:hover {
          background-color: #a2c7db;
        }
      }
    }
  }
}
