$border-style: 1px solid #e0e0e0;

.parkingTrustArcBanner {
  :global {
    .trustarc-banner-wrapper {
      box-sizing: border-box;
      max-width: 100%;
      height: 100%;
      margin: 0;
      padding-top: 1rem;
      box-shadow: none;

      > * {
        max-width: 40rem;
        margin: auto auto;
      }
    }
    .trustarc-banner-background {
      background-color: transparent !important;
    }
    .trustarc-banner-container {
      box-sizing: border-box;
      padding: 1rem;
      border-radius: 15px 15px 0 0;
      background-color: white;
      display: flex;
      flex-direction: column;
      border-top: $border-style;
      border-left: $border-style;
      border-right: $border-style;
      .banner-details-container {
        margin: 0;
        .description-group {
          display: flex;
          flex-direction: row;
          gap: 1rem;
          @media screen and (max-width: 640px) {
            flex-direction: column;
            gap: 0.5rem;
          }
          .partners-container {
            padding-left: 0;
          }
        }
      }

      .trustarc-consent-buttons {
        padding: 0.5rem 0 0 0;

        display: grid;
        max-width: 100%;
        gap: 0.2rem;
        > * {
          width: 100%;
          margin: 0;
        }
        .trustarc-agree-btn {
          grid-column-end: span 2;
          background-color: #02198b; // Adsense Related Search colour
          color: white;
          font-weight: bold;
          height: 3rem;
        }
        .trustarc-manage-btn,
        .trustarc-declineall-btn {
          background-color: transparent;
          border: none;
          text-decoration: underline;
        }
      }
    }
    .trustarc-banner-footer {
      box-sizing: border-box;
      border-radius: 0 0 15px 15px;
      border-left: $border-style;
      border-right: $border-style;
      border-bottom: $border-style;
    }

    .trustarc-banner-header {
      font-size: 11px;
    }
    .trustarc-banner-body {
      padding-top: 0.5rem;
      line-height: normal;
      font-size: 8px;
    }
  }
}
