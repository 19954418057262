@import "src/styles/common";

// Many of these styles were adapted directly from src/adsense/layout/ParkWeb.js, which is the original ParkWeb layout style file.
.parkWebLander {
  margin: 0;
  padding: 0;
  background: linear-gradient(180deg, #00112c, #041a87);
  min-height: 100vh;
  font-family: GD Sherpa, arial;

  :global #topBanner {
    padding-bottom: 2em;
  }
}

.domainCard {
  background-color: #ffffff;
  width: 35em;
  border-radius: 14px;
  padding: 1em 2em;
}

.getDomain {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 1em;
  max-width: 15em;

  :global #getButtonBox {
    background-color: #111111;
    border-radius: 6px;
    width: 180px;
  }

  :global #getButtonBoxLink {
    line-height: 50px;
    text-decoration: none;
    color: white;
    padding: 1.5em;
  }

  @media (max-width: $screen-sm) {
    padding-top: 1em;
    margin: auto;
  }
}

.contentMain {
  display: flex;
  justify-content: space-around;
  padding: 2em;
  padding-top: 0;
  padding-bottom: 2em;

  :global #relatedLinks #ads {
    border: solid #d6d6d6 1px;
    background-color: #ffffff;
  }

  // verizon styles
  :global #verizon-feed span {
    color: #929292;
  }
  :global #verizon-feed a {
    text-decoration: none;
    color: #111111;
  }

  :global #verizon-feed .verizon-ads .adLink {
    color: #0000ee;
  }

  :global #relatedLinks,
  :global #ads {
    width: 100%;
  }
}

.contentLayout {
  color: #111111;
  display: flex;
  justify-content: space-around;
  margin-top: 3em;
  border-bottom: 1px solid #d4dbe0;
  padding-bottom: 2em;
  margin-bottom: 1em;

  @media (max-width: $screen-sm) {
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 2em;
    margin-top: 2em;
  }
}

.domain {
  font-size: 35px;
  font-weight: 600;
  text-align: left;

  @media (max-width: $screen-sm) {
    text-align: center;
  }
}

.domainInfo {
  text-align: left;

  @media (max-width: $screen-sm) {
    text-align: center;
  }
}
