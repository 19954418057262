.domainDisplayLayout {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.intlMessage {
  padding-top: 8px;
  font-size: 12px;
}
.internationalizedLink {
  color: inherit;
  text-decoration: underline;
}
