@import "src/styles/common";

.paddingTop {
  padding-top: 1rem;
}

.paddingBottom {
  padding-bottom: 3rem;
}

.boxShadow {
  box-shadow: gray 0px -1px 10px;
}

.domainBanner {
  background-color: #d8eeef;
  @media (max-width: $screen-lg) {
    padding-bottom: 10px;
  }
}

.boxedRelatedSearches :global #relatedLinks {
  box-shadow: 0px 5px 18px -8px black;
  border-radius: 5px;
  margin-top: -45px;

  @media (max-width: $screen-md) {
    padding: 1rem;
  }
}
