.spaceBetween {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 0.5rem;
  padding: 0 1rem;
}

.centerText {
  text-align: center;
}

.parkwebFooter {
  padding-top: 1rem;
}

.parkwebDisclaimerText {
  margin: 1rem;
  line-height: 1.5rem;
  font-size: small;
  text-align: center;
}

.darkMode {
  color: white !important;
  a {
    color: white !important;
  }
}
