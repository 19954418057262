@import "src/styles/common";

.topBanner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 5em;
  padding-top: 1.5em;

  @media (max-width: $screen-xxs) {
    padding: 1rem;
    padding-left: 0.5rem;

    iframe {
      width: 10rem;
      height: 3rem !important;
    }
  }
}

.topBannerWithColumnFlex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 3rem;
  border-bottom: 1px solid lightgray;
  margin-bottom: 1rem;
  @media (max-width: $screen-xs) {
    flex-direction: column-reverse;
    padding: 1rem 2rem;
    gap: 1rem;
  }
}
