// breakpoints for media queries
$screen-xxl: 1720px;
$screen-lg: 1200px;
$screen-md: 992px;
$screen-sm: 768px;
$screen-xs: 520px;
$screen-xxs: 450px;

body {
  margin: 0;
  padding: 0;
}
