@import "src/styles/common";

$block-width: 35rem;

// Many of these styles were adapted directly from src/adsense/layout/ParkWeb.js, which is the original ParkWeb layout style file.
.parkWebLander {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  font-family: GD Sherpa, arial;
}

.domainCard {
  width: calc($block-width * 2) + 2;
  border-radius: 14px;
  border: 1px solid black;
  padding: 1em 2em;

  @media (max-width: $screen-md) {
    width: 100%;
  }
}

.getDomain {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 1em;
  max-width: 15em;

  :global #getButtonBox {
    background-color: #111111;
    border-radius: 6px;
    width: 180px;
  }

  :global #getButtonBoxLink {
    line-height: 50px;
    text-decoration: none;
    color: white;
    padding: 1.5em;
  }

  @media (max-width: $screen-sm) {
    padding-top: 1em;
    margin: auto;
  }
}

.contentMain {
  display: flex;
  justify-content: center;
  padding: 0 1em;

  :global #relatedLinks #ads {
    border: solid #d6d6d6 1px;
    background-color: #ffffff;
  }

  // verizon styles
  :global #verizon-feed span {
    color: #929292;
  }
  :global #verizon-feed a {
    text-decoration: none;
    color: #111111;
  }

  :global #verizon-feed .verizon-ads .adLink {
    color: #0000ee;
  }

  :global #relatedLinks,
  :global #ads {
    width: 100%;
  }
}

.contentSuggestions {
  display: flex;
  justify-content: center;
  gap: 2rem;
  padding: 1rem;
  > * {
    width: $block-width;
  }

  @media (max-width: $screen-sm) {
    flex-direction: column-reverse;
    gap: unset;
    > * {
      width: 100%;
    }
  }
}

.contentLayout {
  color: #111111;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0;

  @media (max-width: $screen-sm) {
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 2em;
    margin-top: 2em;
  }
}

.domain {
  font-size: 35px;
  font-weight: 600;
  text-align: left;

  @media (max-width: $screen-sm) {
    text-align: center;
  }
}

.domainInfo {
  text-align: left;

  @media (max-width: $screen-sm) {
    text-align: center;
  }
}
